import './Thankyou.css';
import './Animate.css';
import { useState, useEffect } from 'react';
import MediaQuery from 'react-responsive'
import { useNavigate  } from 'react-router-dom';

import cub from './assets/compo/Cub.png';
import ty from './assets/compo/thankyou.png';

function Thankyou() {
    const router = useNavigate();
    const [isToast, setIsToast] = useState(false);
    const [toastMessage, setToastMessage] = useState('');
    const [countDown, setCountDown] = useState(0);
    const [runTimer, setRunTimer] = useState(true);

    <script>insert_event_code_here;</script>

    useEffect(() => {
        let timerId;

        if (runTimer) {
            setCountDown(60 * 3);
            timerId = setInterval(() => {
                setCountDown((countDown) => countDown - 1);
            }, 1000);
        } else {
            clearInterval(timerId);
        }

        return () => clearInterval(timerId);
    }, [runTimer]);

    useEffect(() => {
        if (countDown < 0 && runTimer) {
            setRunTimer(false);
            setCountDown(0);
        }
    }, [countDown, runTimer]);

    useEffect(() => {
        console.log('active');
        import('react-facebook-pixel')
            .then((x) => x.default)
            .then((ReactPixel) => {
                ReactPixel.init('2057471744443562', null, {
                    autoConfig: true,
                    debug: true,
                }); // facebookPixelId
                ReactPixel.pageView()
                window.fbq('track', 'CompleteRegistration');

                router.events.on('routeChangeComplete', () => {
                    ReactPixel.pageView()
                })

                return () => {
                    router.events.off('routeChangeComplete', () => {
                        ReactPixel.pageView()
                    })
                }
            })
    }, [router.events]);

    const seconds = String(countDown % 60).padStart(2, 0);
    const minutes = String(Math.floor(countDown / 60)).padStart(2, 0);

    useEffect(() => {
        if (!isToast) return;

        const intervalId = setInterval(() => {
            setIsToast(false);
            setToastMessage('');
        }, 5000);

        return () => clearInterval(intervalId);
    }, [isToast]);

    return (
        <section className="py-4 bg-white lg:py-20 wrapper-screen flex-center relative">
            <img className='cub-1' src={cub} alt='' />
            <img className='cub-2' src={cub} alt='' />
            <img className='cub-3' src={cub} alt='' />
            <MediaQuery minWidth={768}>
                <div className='md:absolute top-0 left-0 w-screen md:mb-6 px-4 lg:px-24 py-2 lg:py-6'>
                    <div className='text-start self-center'>
                        <img className="logo-w h-8 h-auto" src="./assets/cc-logo.png" alt="" />
                    </div>
                </div>
            </MediaQuery>
            <div className="px-4 sm:px-6 lg:px-8 mx-auto max-w-3xl">
                <div className="grid grid-cols-1 mx-auto lg:max-w-none">
                    <div className="py-10 px-4 md:p-6 bg-white rounded-3xl card-shadow-cs z-50">
                        <div className="text-center">
                            <MediaQuery maxWidth={767.5}>
                                <img className="logo-w h-8 h-auto mx-auto" src="./assets/cc-logo.png" alt="" />
                            </MediaQuery>
                            <img className='mx-auto ty-compo mt-5 md:mt-0' src={ty} alt="" />
                            {/* <h1 className="text-2xl sm:text-3xl xl:text-4xl font-extrabold">Thank you</h1> */}
                            <h1 className="mt-4 md:mt-8 text-s-greet font-normal tracking-tighter"><span className='font-extrabold'>You’re</span> In</h1>
                            <button className='mt-5 bg-red-900 py-3 px-4 rounded-full text-white text-xl md:text-xl'>{minutes}:{seconds}</button>
                            <p className="px-4 mt-5 font-medium sm:px-0 text-md md:text-2xl 2xl:text-3xl">Welcome to Chain Catalyst by Launchy</p>
                            <p className="px-4 mt-5 font-normal sm:px-0 text-md md:text-md 2xl:text-xl" style={{ lineHeight: '1.5rem' }}>Ready to slam dunk some good luck into your day?</p>
                            <p className="px-10 mt-2 font-normal sm:px-0 text-md md:text-md 2xl:text-xl md:px-40" style={{ lineHeight: '1.5rem' }}>Check your inbox within the next 3 minutes and see it for yourself!</p>
                            <p className="px-16 mt-5 font-normal sm:px-0 text-xs md:text-sm text-gray-400 md:px-44">P.S Check your Spam/Promotions tab if you can’t find our welcome email. We’ve used up our good luck charm today 😉</p>
                        </div>
                    </div>
                    {/* <MediaQuery minWidth={992}>
            <div className="self-center">
              <img src={sheetsImg} alt='sheets' />
            </div>
          </MediaQuery> */}
                </div>
            </div>
            {
                isToast &&
                <div id="toast-bottom-right" className="bg-core-900 flex fixed right-5 bottom-5 items-center p-4 space-x-4 w-full max-w-xs text-gray-50 rounded-lg divide-x divide-gray-200 shadow" role="alert">
                    <div className="text-sm font-normal flex items-center">
                        <svg style={{ color: 'white' }} xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="mr-2 bi bi-exclamation-circle" viewBox="0 0 16 16">
                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" fill="white"></path> <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z" fill="white"></path>
                        </svg>
                        <span>{toastMessage}</span>
                    </div>
                </div>
            }
        </section >
    );
}

export default Thankyou;